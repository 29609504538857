import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'
import backgroundNoise from '../../images/noise-small.png'
import { setVh } from '../../styles/utils/conversion.style'

export const ScrollWrapper = styled.div`
  background-color: ${({ color }) => color};
  background-image: url(${backgroundNoise});
  background-position: 0% 0%;

  ${({ removeNoise }) => removeNoise && `
      background-image: none;
    `}

  ${mq.horizontal} {
    display: flex;
    align-items: flex-end;
    min-width: 100vw;
    ${setVh('min-height', 100)}
  }
`
