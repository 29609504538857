import React from 'react'
import PropTypes from 'prop-types'
import { AnimateSlideInContent, AnimateSlideInMask } from './index.style'

const AnimateSlideIn = ({ direction, delay, children }) => (
  <AnimateSlideInMask>
    <AnimateSlideInContent direction={direction} delay={delay}>
      {children}
    </AnimateSlideInContent>
  </AnimateSlideInMask>
)

AnimateSlideIn.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  direction: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
}

AnimateSlideIn.defaultProps = {
  delay: 0,
  direction: 'bottom',
}

export default AnimateSlideIn
