import React from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import { ScrollBlock } from './index.style'

const ScrollSection = ({
  threshold,
  id,
  repeat,
  offset,
  trackInView,
  width,
  backgroundColor,
  children,
}) => (
  <div
    id={id ? id : undefined}
    data-scroll-section
    data-scroll={id ? true : undefined}
    data-scroll-call={id ? id : undefined}
    data-scroll-repeat={repeat ? repeat : undefined}
    data-scroll-offset={offset ? offset : undefined}
  >
    {trackInView ? (
      <InView triggerOnce={true} threshold={threshold}>
        {({ inView, ref }) => (
          <div ref={ref} data-inview={inView.toString()}>
            <ScrollBlock width={width} backgroundColor={backgroundColor}>
              {React.cloneElement(children, { inView: inView })}
            </ScrollBlock>
          </div>
        )}
      </InView>
    ) : (
      <ScrollBlock width={width}>{children}</ScrollBlock>
    )}
  </div>
)

ScrollSection.propTypes = {
  width: PropTypes.number,
  repeat: PropTypes.bool,
  offset: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  threshold: PropTypes.number,
  trackInView: PropTypes.bool,
  backgroundColor: PropTypes.string,
}

ScrollSection.defaultProps = {
  repeat: false,
  threshold: 0.2,
  trackInView: true,
}

export default ScrollSection
