import styled from 'styled-components'
import { horizontalScale, setVh } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'
import backgroundNoise from '../../images/noise-small.png'

export const ScrollBlock = styled.div`
  position: relative;

  ${mq.horizontal} {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    width: ${({ width }) => (width ? horizontalScale(width) : undefined)};
    ${setVh('height', 100)}
  }

  ${({ backgroundColor }) => {
    if (backgroundColor) {
      return `
          :before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-color: ${backgroundColor};
            background-image: url(${backgroundNoise});

            ${mq.horizontal} {
              left: -0.1rem;
            }
          }
        `
    }
  }}
`
